<template>
    <div class='content_pages-page'>
        <h4 class='page-title'>{{ getActionName }}</h4>
        <div class='widget'>
            <FormBuilder
                class='row'
                v-if='pageType === "edit" ? pageData : true'
                :form-data='formData'
                :save='saveDataFunc'
            />
        </div>
    </div>
</template>

<script>
import { formPreparation } from "@/utils/form-helpers";
import FormBuilder from '@/components/FormBuilder/FormBuilder';

export default {
    name: 'BlogArticleCreate',
    components: { FormBuilder },
    data() {
        return {
            pageType: null,
            pageData: null,
            saveDataFunc: null,
            formData: {
                'slug': {
                    'type': 'text',
                    'label': 'Slug (Не менять!)',
                    'validation': 'max:30',
                    'value': null,
                },
                'title': {
                    'type': 'text',
                    'label': 'Заголовок страницы',
                    'validation': 'required|max:100',
                    'value': null,
                },
                'location': {
                    'type': 'select',
                    'label': 'Локация',
                    'options': [],
                    'validation': 'required',
                    'value': null,
                },
                'category_id': {
                    'type': 'select',
                    'label': 'Категория',
                    'options': [],
                    'validation': 'required',
                    'value': null,
                },
                'image_preview': {
                    'type': 'image',
                    'label': 'Картинка',
                    'class': 'col-6',
                    'aspectRatio': 7/3,
                    'validation': 'required',
                    'value': null,
                    // 'alt': null,
                    // 'title': null,
                },
                'image_preview_mob': {
                    'type': 'image',
                    'label': 'Картинка для телефонов',
                    'class': 'col-6',
                    'aspectRatio': 5/3,
                    'validation': 'required',
                    'value': null,
                    // 'alt': null,
                    // 'title': null,
                },
                'image_preview_title': {
                    'type': 'text',
                    'class': 'col-3',
                    'label': 'Картинка title',
                    'value': null,
                },
                'image_preview_alt': {
                    'type': 'text',
                    'class': 'col-3',
                    'label': 'Картинка alt',
                    'value': null,
                },
                'image_preview_mob_title': {
                    'type': 'text',
                    'class': 'col-3',
                    'label': 'Картинка для телефонов title',
                    'value': null,
                },
                'image_preview_mob_alt': {
                    'type': 'text',
                    'class': 'col-3',
                    'label': 'Картинка для телефонов alt',
                    'value': null,
                },
                'content_preview': {
                    'type': 'textarea',
                    'label': 'Превью текст страницы',
                    'class': 'col-12',
                    'validation': 'required',
                    'value': null,
                },
                'content': {
                    'type': 'editor',
                    'label': 'Текст страницы',
                    'class': 'col-12',
                    'validation': 'required',
                    'value': null,
                },
                'custom_fields': {
                    'type': 'seoFields',
                    'label': 'SEO поля',
                    'class': 'col-12',
                    'value': [
                        {
                            key: 'seo_title',
                            value: '',
                        },
                        {
                            key: 'seo_description',
                            value: '',
                        },
                        {
                            key: 'seo_keywords',
                            value: '',
                        },
                    ],
                },
                'is_published': {
                    'type': 'bool',
                    'label': 'Опубликовано?',
                    'class': 'col-3',
                    'value': false,
                },
            },
        };
    },
    computed: {
        getActionName() {
            if (this.pageType === 'create') {
                return 'Создать статью блога';
            }

            if (this.pageType === 'edit') {
                return 'Редактировать статью блога';
            }
            return '';
        },
    },
    methods: {
        extractPageType() {
            const path = this.$route.path.split('/');
            this.pageType = path[path.length - 1];
        },
        async saveNewData(form) {
            const formData = formPreparation(form);

            try {
                await this.$axios.post('/crm/blog-articles', formData);
                this.$toasted.success(`Статья блога ${form.title} создана!`, {position: "bottom-right"});
            } catch (e) {
                this.handleAxiosError(e);
            }
        },
        handleAxiosError(error) {
            const {data} = error.response;

            if (data.error) {
                this.$toasted.error(data.message, {position: 'bottom-right'});
                return;
            }
            if (data.errors) {
                this.$toasted.error(data.message, {position: 'bottom-right'});
                return;
            }
        },
        async saveEditData(form) {
            const formData = formPreparation(form);

            try {
                const id = this.$route.params.id;
                await this.$axios.post(`/crm/blog-articles/${id}?_method=patch`, formData);
                this.$toasted.success(`Статья блога ${form.title} отредактирована!`, {position: "bottom-right"});
            } catch (e) {
                this.handleAxiosError(e);
            }
        },
        fillFormData(data) {
            for (const key in this.formData) {
                this.formData[key]['value'] = data[key]
            }
        },
        fillSelectFields(data) {
            for (const key in data) {
                this.formData[key]['options'] = data[key];
                if (this.pageType === 'create') {
                    this.formData[key]['value'] = data[key][0].value;
                }
            }
        },
        async fetchEditData() {
            const id = this.$route.params.id;
            if (!id) {
                this.$toasted.error("Нельзя редактировать без ID", {position: "bottom-right"});
                return;
            }

            try {
                const { data } = await this.$axios.get(`/crm/blog-articles/${id}/edit`);
                if (data && data.fields) {
                    this.fillSelectFields(data.fields);
                }
            } catch (e) {
                alert(e);
            }

            try {
                const { data } = await this.$axios.get(`/crm/blog-articles/${id}`);
                if (data && data.data) {
                    this.fillFormData(data.data);
                    this.pageData = data.data;
                }
            } catch (e) {
                alert(e);
            }
        },
        async fetchCreateData() {
            try {
                const { data } = await this.$axios.get('/crm/blog-articles/create');
                if (data && data.fields) {
                    this.fillSelectFields(data.fields);
                }
            } catch (e) {
                alert(e);
            }
        },
    },
    async created() {
        this.extractPageType();
        if (this.pageType === 'edit') {
            await this.fetchEditData();
            this.saveDataFunc = this.saveEditData;
        }
        if (this.pageType === 'create') {
            await this.fetchCreateData();
            this.saveDataFunc = this.saveNewData;
        }
    },
};
</script>

<style scoped>

</style>
